<template>
  <div
    id="auth"
    :style="`background-color: ${appThemeConfig.appBgColor}; padding-top: ${
      isApp ? 0 : 0
    }px`"
  >
    <app-system-bar
      v-if="isApp"
      style="position: fixed; opacity: 0.1"
    ></app-system-bar>

    <div class="auth-content">
      <div class="head-media">
        <video
          v-if="isApp || /chrome/g.test(browser) || /safari/g.test(browser)"
          class="video"
          src="static/media/hxf.mp4"
          autoplay
          muted
          loop
          preload="auto"
          x5-video-player-type="h5-page"
        ></video>
        <v-img
          v-else
          lazy-src="https://picsum.photos/id/11/10/6"
          width="100%"
          height="100%"
          src="https://picsum.photos/id/11/500/300"
        ></v-img>
        <div class="head-media-filter"></div>
        <div
          v-show="showLogin"
          class="head-media-content"
          :style="`padding-top: ${isApp ? systemBarHeight : 0}px`"
        >
          <div class="app-reg">
            <v-btn
              small
              :style="`top: ${isApp ? systemBarHeight : 0}px`"
              class="app-reg-button"
              outlined
              color="#eee"
              @click="doRegister"
              @touchstart="zzzMid"
            >
              注册<v-icon light x-small>fa fa-user-plus</v-icon>
            </v-btn>
          </div>
          <div class="app-title">NOTEVER</div>
          <div class="app-slogn">NOT GIVEUP, EVER STUDY</div>
        </div>
      </div>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate, setSystemColor } from "@/mixin/mixin";
import AppSystemBar from "@/components/app/appSystemBar";
import { isEmpty } from "@/utils/common";

export default {
  name: "authIndex",
  mixins: [buttonVibrate, setSystemColor],
  components: { AppSystemBar },
  computed: {
    formatTranslate() {
      return function (text) {
        if (!isEmpty(text)) {
          return text.replace(/\n/g, " ");
        }
        return "";
      };
    },
    showLogin() {
      let path = this.$route.path;
      return path === "/auth/login";
    },
    ...mapGetters({
      isApp: "isApp",
      appThemeConfig: "appThemeConfig",
      browser: "browser",
      systemBarHeight: "systemBarHeight",
    }),
  },
  data: () => ({
    firstCreat: true,
  }),
  methods: {
    doRegister() {
      this.$router.push("/auth/register");
    },
    init() {
      this.$store.dispatch("setShowSysAppTab", false);
      this.$store.dispatch("setAppSystemBarColor", "#8ca0ff");
      this.setSystemFootColor(
        this.appThemeConfig.frameWorkColorList[1].footerBarColer
      );
    },
  },
  created() {
    console.info("auth组件被创建");
    this.init();
  },
  mounted() {
    console.info("auth组件被挂载");
  },
  activated() {
    console.info("auth组件被激活");
    if (this.firstCreat) {
      this.firstCreat = false;
    } else {
      this.init();
    }
  },
  deactivated() {
    console.info("auth组件被暂停");
  },
};
</script>

<style lang="scss" scoped>
#auth {
  height: 100vh !important;
  max-height: 100% !important;
  overflow: hidden;
  background-color: #8ca0ff;

  animation: slogn-motion 3s ease;
  -webkit-animation: slogn-motion 3s ease; /*Safari and Chrome*/
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
  animation-fill-mode: forwards;
  --webkit-animation-fill-mode: forwards;
  @keyframes slogn-motion {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @-webkit-keyframes slogn-motion /*Safari and Chrome*/ {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .auth-content {
    overflow-y: scroll;
    width: 100%;
    max-width: 100%;
    height: 100%;
    word-wrap: break-word;
    background-color: #8ca0ff;
    position: relative;

    .reg-button {
      margin-top: 1.5rem;
      width: 100%;
      height: 2.7rem;
      color: #eeeeee;
      font-weight: 500;
      border-radius: 1rem !important;
      letter-spacing: 0.5rem !important;
    }

    .head-media {
      position: relative;
      height: calc(28% + 3rem);
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 0 1.5rem 1.5rem;
      > video {
        height: 100%;
        filter: blur(3px);
        @media (min-width: 540px) {
          width: 100%;
          height: auto;
        }
        @media (max-height: 660px) {
          width: 100%;
          height: auto;
        }
      }
    }

    .head-media-filter {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: calc(100% + 3rem);
      opacity: 0.8;
      background: #8ca0ff;
      transform: translate(0px, -1px);
      --webkit-transform: translate(0px, -1px);
      --moz-transform: translate(0px, -1px);
      @media (min-width: 540px) {
        width: 100%;
      }
      @media (max-height: 660px) {
        width: 100%;
      }
    }
    .head-media-content {
      z-index: 3;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      color: #eeeeee;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .app-reg {
        position: absolute;
        right: 0;
        top: 0;
        text-align: right;
        margin: 0.5rem 0.5rem 0 0;
        .app-reg-button {
          //background-color: #555;
          letter-spacing: 0.2rem !important;
          border-radius: 0.5rem !important;
          font-size: 0.8rem;
        }
      }
      .app-title {
        color: #ffffff;
        margin: -3.5rem 0 0.3rem 0;
        align-self: center;
        font-size: 2rem;
        font-family: pangmenzhengdaoregular, NotoSansHans， 微软雅黑, sans-serif;
      }
      .app-slogn {
        font-size: 0.8rem;
        letter-spacing: 0.2rem !important;
      }
    }
  }
}
</style>
